const APP_CONFIGS = {
  // process.env.DEPLOY_PRIME_URL is a built-in environment variable from netlify
  url:
    process.env.BRANCH && process.env.BRANCH !== 'master'
      ? process.env.DEPLOY_PRIME_URL
      : 'https://cafemom.com',
  name: 'CafeMom.com',
  title: 'Parenting, Baby Names, Celebrities, and Royal News',
  logo: '/images/logo/logo-300x300.png',
  description:
    'CafeMom is a supportive community where moms can get parenting tips and updates, real talk, laughs, and the latest celebrity news all in one place',
  facebookAppId: '541143744246397',
  // Default language
  language: 'en',
  envUrl: process.browser ? window.location.protocol + '//' + window.location.host : null,
  // Available locales
  locales: {
    en: {
      title: 'English',
    },
  },
  // Max number of posts to load via infinite scroll
  maxInfiniteScrollPosts: 3,
  // Number of pixels to look ahead of scroll to preload next article
  infiniteScrollLookahead: 2000,
  languageList: ['en'],
  prop30SiteName: 'thestir',
  noSkimlinksForTheseCategorySlugs: ['parenting-shopping', 'lifestyle-health-and-wellness'],
  ctaBannerData: {
    imageNames: {
      desktop1x: 'momlife_cta_desktop.jpg',
      desktop2x: 'momlife_cta_2x_desktop.jpg',
      mobile1x: 'momlife_cta_mobile.jpg',
      mobile2x: 'momlife_cta_2x_mobile.jpg',
    },
    altText: 'Talk to moms about mom stuff. And make money doing it.',
    buttonUrl: `https://cafemom.com/momlife?utm_source=CafeMom.com&utm_medium=site_banner`,
    styles: `
    margin-bottom: 3.125rem
  `,
  },
}

module.exports = APP_CONFIGS

import avenirNextCondensed from '../statics/fonts/AvenirNextCondensed-Regular.ttf'
import avenirNextCondensedBold from '../statics/fonts/AvenirNextCondensed-Bold.ttf'
import FuturaPTBook from '../statics/fonts/FuturaPTBook.otf'
import FuturaPTBold from '../statics/fonts/FuturaPTBold.otf'
import { createFontStyles } from 'wsc/utils/fontFace'

export const AVENIR_NEXT_CONDENSED_FONT_CONFIG = [
  {
    fontFamily: 'Avenir Next Condensed',
    url: avenirNextCondensed,
    format: 'truetype',
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  {
    fontFamily: 'Avenir Next Condensed',
    url: avenirNextCondensedBold,
    format: 'truetype',
    fontWeight: 'bold',
    fontStyle: 'normal',
  },
]

export const FUTURA_PT_FONT_CONFIG = [
  {
    fontFamily: 'Futura PT',
    url: FuturaPTBook,
    format: 'opentype',
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  {
    fontFamily: 'Futura PT',
    url: FuturaPTBold,
    format: 'opentype',
    fontWeight: 'bold',
    fontStyle: 'normal',
  },
]

export const FONT_FACE_AVENIR_NEXT_CONDENSED = createFontStyles(AVENIR_NEXT_CONDENSED_FONT_CONFIG)
export const FONT_FACE_FUTURA_PT = createFontStyles(FUTURA_PT_FONT_CONFIG)

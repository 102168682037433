import { setConfig, getConfig } from 'wsc/globalConfig'

const styleConfig = {
  COLORS: {
    // CM COLORS BUILDS
    CM_VERY_LIGHT_BLUE: '#CCF0F2',
    CM_LIGHT_BLUE: '#66D3D7',
    CM_BLUE: '#00B6BD',
    CM_SOFT_BLUE: '#A8CCEC',
    CM_BG_BLUE: '#65BFC9',
    CM_VERY_LIGHT_GREEN: '#6D9DAA',
    CM_LIGHT_GREEN: '#0B5C71',
    CM_GREEN: '#003B49',
    CM_GREEN_08: '#003B4914',
    CM_DARK_GREEN: '#001E25',
    CM_LIGHT_RED: '#9F4260',
    CM_RED: '#841538',
    CM_DARK_RED: '#420B1D',
    CM_VERY_LIGHT_YELLOW: '#FFF4D0',
    CM_LIGHT_YELLOW: '#FCDD6E',
    CM_YELLOW: '#F8C31B',
    CM_DARK_YELLOW: '#BC941F',
    CM_SHADOW_YELLOW: '#FCEE21',
    CM_LIGHT_GRAY: '#CCCCCC',
    CM_VERY_LIGHT_GRAY: '#EEEEEE',
    CM_DARK_GRAY: '#666666',
    CM_YELLOW_LIGHT: '#fcc72e',
    CM_BLACK_BLUE: '#013a49',
    CM_PINK: '#B0255B',
    CM_PURPLE: '#B3B5DC',
    CM_POPUP_LIGHT_GREEN: '#E0F6F7',
    CM_POPUP_HOVER_GREEN: '#99E2E5',
    CM_MOMLIFE_BG: '#20124d',
  },
  FONT_FAMILIES: {
    SERIF: `'Playfair Display', serif`,
    SANSSERIF: `'Open Sans', sans-serif`,
    JWPLAYER: `'JW Player Font Icons'`,
    BALOO: `'Baloo 2', cursive`,
    AVENIR_NEXT_CONDENSED: `'Avenir Next Condensed'`,
    FUTURA_PT: `'Futura PT'`,
  },
}

setConfig('StyleConfig', styleConfig)

export default getConfig('StyleConfig')
